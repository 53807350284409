import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Game } from "./components/Game";
import React, { useEffect, useState } from "react";
import { Infos } from "./components/panels/Infos";
import { useTranslation } from "react-i18next";
import { InfosFr } from "./components/panels/InfosFr";
import { Settings } from "./components/panels/Settings";
import { useSettings } from "./hooks/useSettings";
import { Stats } from "./components/panels/Stats";
import { Worldle } from "./components/Worldle";

function App() {
  const { i18n } = useTranslation();

  const [infoOpen, setInfoOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  const [settingsData, updateSettings] = useSettings();

  useEffect(() => {
    if (settingsData.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [settingsData.theme]);

  return (
    <>
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        theme={settingsData.theme}
        autoClose={2000}
        bodyClassName="font-bold text-center"
      />
      {i18n.resolvedLanguage === "no" ? (
        <InfosFr
          isOpen={infoOpen}
          close={() => setInfoOpen(false)}
          settingsData={settingsData}
        />
      ) : (
        <Infos
          isOpen={infoOpen}
          close={() => setInfoOpen(false)}
          settingsData={settingsData}
        />
      )}
      <Settings
        isOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        settingsData={settingsData}
        updateSettings={updateSettings}
      />
      <Stats
        isOpen={statsOpen}
        close={() => setStatsOpen(false)}
        distanceUnit={settingsData.distanceUnit}
      />
      <div className="flex justify-center flex-auto dark:bg-slate-900 dark:text-slate-50">
        <div className="w-full max-w-lg flex flex-col">
          <header className="border-b-2 px-3 border-gray-200 flex">
            <button
              className="mr-3 text-xl"
              type="button"
              onClick={() => setInfoOpen(true)}
            >
              ❓
            </button>
            <h1 className="text-4xl font-bold uppercase tracking-wide text-center my-1 flex-auto">
              <span style={{ color: "#000000" }}>K</span>
              <span style={{ color: "#000000" }}>O</span>
              <span style={{ color: "#000000" }}>M</span>
              <span
                style={{
                  color: "#FFFFFF",
                  textShadow:
                    "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                }}
              >
                M
              </span>
              <span
                style={{
                  color: "#FFFFFF",
                  textShadow:
                    "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                }}
              >
                U
              </span>
              <span
                style={{
                  color: "#FFFFFF",
                  textShadow:
                    "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                }}
              >
                N
              </span>
              <span style={{ color: "#149954" }}>D</span>
              <span style={{ color: "#149954" }}>L</span>
              <span style={{ color: "#149954" }}>E</span>
              <span style={{ color: "#149954" }}> </span>
              <span>🇵🇸</span>
            </h1>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setStatsOpen(true)}
            >
              📈
            </button>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setSettingsOpen(true)}
            >
              ⚙️
            </button>
          </header>
          <Game settingsData={settingsData} />
          <footer className="flex justify-center text-sm mt-8 mb-1">
            ❤️ <Worldle />? -
            <a
              className="underline pl-1"
              href="https://www.buymeacoffee.com/oyvindbs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Kjøp oss en kaffe!"}
            </a>
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
